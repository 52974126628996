export default {
    DEFAULT_DATE_FORMAT: "YYYY-MM-DD HH:mm:ss",
    SERVER_DATE_FORMAT: "YYYY-MM-DD[T]HH:mm:ss",
    ONLY_DATE_FORMAT_MONTH_NAME: "MMMM D, YYYY",
    AM_PM_FORMAT: "YYYY-MM-DD hh:mm:ss A",
    ONLY_TIME_AM_PM_FORMAT: "h:mm A",
    TIME_FORMAT: "HH:mm:ss",
    TIME_FORMAT_HH_MM: "HH:MM",
    ONLY_DATE_FORMAT: "YYYY-MM-DD",
    IMAGES:{
        LOGO: process.env.PUBLIC_URL + '/images/logo.png',
        LOGOMINI: process.env.PUBLIC_URL + '/images/favicon.png',
        DEFAULT_PERSON: process.env.PUBLIC_URL + '/images/default-person.png',
        DIGILOCKER_LOGO: process.env.PUBLIC_URL + '/images/digiLocker-small.png',
        AADHAAR_LOGO: process.env.PUBLIC_URL + '/images/aadhaar_logo.png',
    },
    DIGILOCKER: {
        URL: "https://api.digitallocker.gov.in/public/oauth2/1/authorize?response_type=code&client_id=@clientId&redirect_uri=@redirectUrl&state=@state"
    },
    ROLES : {
        SUPER_ADMIN: "51561f27-6f87-4ace-bb46-0d104d465c1c",
        COMPANY_ADMIN: "685c29b5-acca-4ea1-9850-7231b2ac6d05",
        VENDOR_ADMIN: "6cf06d2a-0770-4269-a7ee-ff6f7a1cf0af",
        COMPANY_AGENT: "867ef3ff-8643-4f10-93ed-34447882d1fe",
        VENDOR_AGENT: "4b009f40-64f6-4c2f-bd9c-78851bc517da",
        TA_AGENT:"089b6dcf-d8fc-426e-a6f1-0c02313e8e1a"
    },
    LEVEL : {
        FIRST : 1,
        SECOND : 2,
        THIRD : 3
    }
};
