import React, {useEffect,useState,createContext} from 'react';
import {IRootState} from "../../store/AppStore";
import {BrowserRouter as Router, Route, Switch, useHistory} from 'react-router-dom';
import NotFound from "../pages/NotFound";
import PageLoading from "../shared/PageLoading";
import {loading} from "../shared/Common";
import {ToastContainer} from "react-toastify";
import {useSelector} from "react-redux";
import { auth } from '../../util/Auth';
import LoadingTest from "../shared/LoadingPreview.component";

const LoginPage = React.lazy(() => import('../pages/auth/LoginPage'));
const ForgotPasswordPage = React.lazy(() => import('../pages/auth/ForgotPasswordPage'));
const ResetPasswordPage = React.lazy(() => import('../pages/auth/PasswordReset'));
const ChangePasswordPage = React.lazy(() => import('../pages/ChangePasswordPage'));
const DefaultLayout = React.lazy(() => import('../layouts/DefaultLayout'));
const TALayout = React.lazy(() => import('../layouts/TALayout'));
const RequestInfoLayout = React.lazy(() => import('../layouts/RequestInfoLayout'));

interface IProps {

}

interface AppContextInterface {
    role: string;
    userStatus: string;
    setUserStatus : any
}

export const AppCtx = createContext<AppContextInterface | null>(null);
const sampleAppContext: AppContextInterface = {
    role: "",
    userStatus : "",
    setUserStatus : ""
};
export interface ISendBy {
    send_sms: boolean,
    send_email: boolean
}

declare global {
    interface Window {
        requestInfoOtpSendBy: ISendBy|null,
    }
}


export default function AppContainer(props: IProps) {
    const {showPageLoading} = useSelector((state: IRootState) => state.ui);

    const [role, setRole] = useState('')
    const [userStatus,setUserStatus] = useState('')

    const history = useHistory();
    
    useEffect(() => {
        const user =  auth.getUser();
        
        // setRole( user.roles[0].name);
        setRole( user === null ? '' :  user.roles[0].name);
        
    }, []);

    return (
        <>
        <LoadingTest loading={showPageLoading} message="Please wait while we load your data"/>
            <PageLoading show={showPageLoading}/>
            <Router>
                <React.Suspense fallback={loading()}>
                <AppCtx.Provider value={{'role' : role, 'userStatus' : userStatus, 'setUserStatus' : setUserStatus }}>
                    <Switch>
                        <Route path="/login" > <LoginPage role={role} setRole={setRole}/> </Route>
                        <Route path="/forgot-password" component={ForgotPasswordPage} />
                        <Route path="/reset-password/:token/:email" component={ResetPasswordPage} />
                        <Route path="/change-password" component={ChangePasswordPage}/>
                        <Route path="/ri" component={RequestInfoLayout}/>
                        <Route path="/"> 
                            { role === 'admin' || role === 'agent'  ? <DefaultLayout /> : 
                                ( role === 'TA' ? <TALayout /> : (
                                    role === '' ? <LoginPage role={role} setRole={setRole}/> : <LoginPage role={role} setRole={setRole}/>
                                ))
                            }
                        </Route>
                        
                        <Route component={NotFound}/>
                    </Switch>
                </AppCtx.Provider>
                </React.Suspense>
            </Router>
            <ToastContainer/>
        </>
    );
}
