import moment from "moment-timezone";
import AppConstants from "./AppConstants";
import {OrganizationType} from "./Enums";

export interface IOrganization {
    is_video_compare_active: number,
    organization_xid: string | null,
    organization_type: number,
    parent_organization_xid: number | null,
    timezone_name: number | null,
    //is_admin: boolean,
    name: string | null,
    allow_send_sms: string | null,
    allow_send_email: string | null,
    is_candidate_module_enabled: number,
    is_customer_module_enabled: number,
    is_routing_partner: boolean,
    is_audio_compare_active:number,
    is_assessment_active:number,
    level : number | null;
}

interface IRole {
    role_xid: string,
    name: string,
    claims: IClaim[],
}

interface IClaim {
    type: string
    value: string,
}

interface IAuth {
    access_token: string,
    user : AuthUser
}

interface AuthUser {
    user_xid: string;
    first_name: string,
    last_name: string,
    email: string,
    user_name: string,
    phone_number: string,
    timezone_name: string,
    organizations: IOrganization[],
    first_login: boolean,
    //organization: IOrganization,
    current_organization?: IOrganization,
    roles: IRole[],
    claims: IClaim[],
    meet_user_id : string, 
    is_superadmin : boolean, 
}

export default class Auth {
    static getCurrentOrganization() {
        throw new Error("Method not implemented.");
    }
    storage = localStorage;

    isLoggedIn(): boolean {
        return !!this.getUser();
    }

    getUser(): AuthUser | null {
        const user = this.getItemFromStorage('user');
        return (user) ? JSON.parse(user) : null;
    }

    setUser(user: AuthUser) {
        this.setItemToStorage('user', JSON.stringify(user));
    }

    setAuth(user: IAuth): void {
        user.user.current_organization = user.user.organizations[0];
        console.log("userr--set auth-",user.user)
        this.setItemToStorage('tkn', user.access_token);
        this.setItemToStorage('user', JSON.stringify(user.user));
    }

    getFirstName(): string {
        const user = this.getUser();
        return (user && user.first_name) || '';
    }

    updateUserInfo(updateInfo: any): void {
        const userInfo = this.getUser();
        const user = {
            ...userInfo,
            first_name: updateInfo.first_name,
            last_name: updateInfo.last_name,
            phone_number: updateInfo.phone_number,
            timezone_name: updateInfo.timezone_name,
        };
        if (userInfo) {
            this.setItemToStorage('user', JSON.stringify(user));
        }
    }

    getCurrentOrganization(): IOrganization | null {
        const user = this.getUser();
        return (user && user.current_organization) ? user.current_organization : null;
    }

    getCurrentOrganizationId(): string {
        const organization = this.getCurrentOrganization();
        return (organization && organization.organization_xid) ? organization.organization_xid : "";
    }

    setCurrentOrganization(organization: IOrganization) {
        const user = this.getUser();
        if (user) {
            user.current_organization = organization;
            this.setItemToStorage('user', JSON.stringify(user));
        }
    }

    getToken(): any {
        const token = this.getItemFromStorage('tkn');
        return (token) ? token : null;
    }

    /*is_admin() {
        const user = this.getUser();
        return (user && user.organization && user.organization.is_admin);
    }*/

    getClaims() {
        const user = this.getUser();
        return (user && user.roles.length > 0) ? user.roles[0].claims : [];
    }

    hasRole(roleXid: string) {
        const user = this.getUser();
        if (user && user.roles.length > 0) {
            const hasRole = user.roles.find((role: IRole) => role.role_xid === roleXid);
            return !!(hasRole);
        }
        return false;
    }

    isSuperAdmin() {
        const user = this.getUser();
        if (user && user.is_superadmin ) {
            return true;
        }
        return false;
    }

    hasOrgModuleAccess() {

    }

    hasModulePermission(permission: string) {
        let allow = false;
        const currentOrganization = this.getCurrentOrganization();
        switch (permission) {
            case "organization":
                allow = ((currentOrganization?.organization_type === OrganizationType.SuperAdmin || currentOrganization?.organization_type === OrganizationType.Company)
                    && (auth.hasRole(AppConstants.ROLES.SUPER_ADMIN) || auth.hasRole(AppConstants.ROLES.COMPANY_ADMIN)));
                break;
            case "question":
                allow = ((currentOrganization?.organization_type === OrganizationType.Company || currentOrganization?.organization_type === OrganizationType.CompanyVendor)
                    && (auth.hasRole(AppConstants.ROLES.SUPER_ADMIN) || auth.hasRole(AppConstants.ROLES.COMPANY_ADMIN) || auth.hasRole(AppConstants.ROLES.VENDOR_ADMIN)));
                break;
            case "user":
                allow = (auth.hasRole(AppConstants.ROLES.SUPER_ADMIN) || auth.hasRole(AppConstants.ROLES.COMPANY_ADMIN) || auth.hasRole(AppConstants.ROLES.VENDOR_ADMIN));
                break;
            case "candidate":
                allow = ((currentOrganization?.organization_type === OrganizationType.Company
                    || currentOrganization?.organization_type === OrganizationType.CompanyVendor) && currentOrganization?.is_candidate_module_enabled === 1);
                break;
            case "customer":
                allow = ((currentOrganization?.organization_type === OrganizationType.Company
                    || currentOrganization?.organization_type === OrganizationType.CompanyVendor) && currentOrganization?.is_customer_module_enabled === 1);
                break;
            case "reports":
                allow = ((currentOrganization?.organization_type === OrganizationType.Company
                    || currentOrganization?.organization_type === OrganizationType.CompanyVendor));
                break;
            case "calendar":
                                    allow = ((currentOrganization?.organization_type === OrganizationType.SuperAdmin || currentOrganization?.organization_type === OrganizationType.Company)
                    && (auth.hasRole(AppConstants.ROLES.SUPER_ADMIN) || auth.hasRole(AppConstants.ROLES.COMPANY_ADMIN)));
                break;

            case "tamodule":
                    allow = ((currentOrganization?.organization_type === OrganizationType.SuperAdmin || currentOrganization?.organization_type === OrganizationType.Company)
                    && (auth.hasRole(AppConstants.ROLES.SUPER_ADMIN) || auth.hasRole(AppConstants.ROLES.COMPANY_ADMIN)));
                break;
            case "request":
                    allow = ((currentOrganization?.organization_type === OrganizationType.SuperAdmin || currentOrganization?.organization_type === OrganizationType.Company)
                    && (auth.hasRole(AppConstants.ROLES.SUPER_ADMIN) || auth.hasRole(AppConstants.ROLES.COMPANY_ADMIN)));
                break;

            case "audio_compare" :
                allow = ((currentOrganization?.organization_type === OrganizationType.Company
                    || currentOrganization?.organization_type === OrganizationType.CompanyVendor) && currentOrganization?.is_audio_compare_active === 1);
                    break;
            case "video_compare" :
                        allow = ((currentOrganization?.organization_type === OrganizationType.Company
                            || currentOrganization?.organization_type === OrganizationType.CompanyVendor) && currentOrganization?.is_video_compare_active === 1);
                    break;
            case "blockedUser":
                allow = ((currentOrganization?.organization_type === OrganizationType.SuperAdmin || 
                    currentOrganization?.organization_type === OrganizationType.Company)
                    && (auth.hasRole(AppConstants.ROLES.SUPER_ADMIN) || auth.hasRole(AppConstants.ROLES.COMPANY_ADMIN)));
                break;
            case "assessment":
                allow = ((currentOrganization?.organization_type === OrganizationType.Company
                    || currentOrganization?.organization_type === OrganizationType.CompanyVendor) && currentOrganization?.is_assessment_active === 1);
                    break; 
            case "assignUser":
                 allow = ((currentOrganization?.organization_type === OrganizationType.SuperAdmin || 
                        currentOrganization?.organization_type === OrganizationType.Company)
                        && (auth.hasRole(AppConstants.ROLES.SUPER_ADMIN) || auth.hasRole(AppConstants.ROLES.COMPANY_ADMIN)));
                    break;
            case "taAssignUser":
                 allow = ((currentOrganization?.organization_type === OrganizationType.SuperAdmin || 
                        currentOrganization?.organization_type === OrganizationType.Company)
                        && (auth.hasRole(AppConstants.ROLES.TA_AGENT)) && auth.isSuperAdmin());
                    break;
        }
        return allow;
    }

    hasPermission(permission: string): boolean {
        const claims = this.getClaims();
        if (claims.length > 0) {
            return claims.some((item: any) => {
                return item.value === permission;
            });
        }
        return false;
    }

    hasPermissions(permissions: string[] = []): boolean {
        const claims = this.getClaims();
        if (claims.length > 0) {
            const intersection = claims.filter((item: any) => {
                return permissions.includes(item.value);
            });
            return intersection.length === permissions.length;
        }
        return false;
    }

    hasAnyPermissions(permissions: string[] = []): boolean {
        const claims = this.getClaims();
        if (claims.length > 0) {
            return claims.some((item: any) => {
                return permissions.includes(item.value);
            });
        }
        return false;
    }

    getTimezone(): any {
        const user: any = this.getUser();
        if (user && user.timezone_name) {
            return (moment.tz.zone(user.timezone_name)) ? user.timezone_name : moment.tz.guess();
        } else {
            return moment.tz.guess();
        }
    }

    getItemFromStorage(key: any): any {
        return this.storage.getItem(key);
    }

    setItemToStorage(key: any, value: any): void {
        this.storage.setItem(key, value);
    }

    logout(): void {
        this.storage.removeItem('user');
        this.storage.removeItem('tkn');
    }
}

export const auth = new Auth();
